<template> 
  <div>
   <Header/> 
   <b-row style="margin-top:5rem" class="p-1">
    <Products/>
    <CurrencyOverview/>
  </b-row>
  <!-- <Footer/>     -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Header from '@/modules/shop/components/home/Header'
import Products from '@/modules/shop/components/orderSummary/Products'
import CurrencyOverview from '@/modules/shop/components/orderSummary/CurrencyOverview'
import Footer from '@/modules/shop/components/home/Footer'

import Ripple from 'vue-ripple-directive'
import { overWriteAxiosHeaders, toJson, makePayloadRequestProducts, generateProductsToAddCart, showAlertMessage} from '@/helpers/helpers'
import { generateQueryParams } from '@/helpers/landing'
import { pushImpressionsToDL } from '@/helpers/dataLayerHelpers'


export default {
  async created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)    
    if(this.queryParams){
      overWriteAxiosHeaders( this.queryParams ) //sobreescribo axios
    }
      if(this.isLogged){
        document.title = `${this.user.name} ${this.user.lastname}`  

        if(!this.currencies.length){        
          const currencies = await this.fetchCurrencies()
          this.setCurrencies(currencies)
        }
      }    
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  directives: {
    Ripple,
  },
  components: { 
    Header,  
    Products,  
    CurrencyOverview,
    Footer
  },   
  data(){
    return {
      params: this.$route.query,
      isLoadingData: false,            
    }
  },
  computed:{
    ...mapState('start',['currencies']),
    ...mapState('products',['isloadingProducts', 'products','relatedProducts']),    
    ...mapState('shop',['cart']),       
    ...mapState('auth',['queryParams','user', 'isLogged']),                                
  }, 
  methods: {                
    ...mapActions('start',['fetchCurrencies']),     
    ...mapActions('auth',['loginWeb']),     
    ...mapActions('products',['getInitialProducts','fetchRelatedProducts','fetchProductsByCategories']),                
    ...mapMutations('start',['setCurrencies']),
    ...mapMutations('auth',['setDataQueryParams', 'setUser', 'logout']),  
    ...mapMutations('products',['setProducts','setRelatedProducts']),
    ...mapMutations('shop',['setCurrency','addProductInCartInitial','setHotelCliente','setTipoCliente','resetCartCustomerBreakdownOrder']),
    initialParams(){   
      const paramsToUse =  Object.keys(this.params).length === 0 ? toJson(this.queryParams) : toJson(this.params)      
      const params = generateQueryParams( toJson(paramsToUse))      
      this.setDataQueryParams(params) 
    },
    async loginApp(){      
        const { ok, user } = await this.loginWeb( this.queryParams.token )        
        if(ok){
          this.setUser( user )
        }
        if(!ok){
          this.logout()
          this.setDataQueryParams(null)      
          this.$router.push({name: 'not-authorized' })
        }
    },
    resetState(){
      const thereAreProducts = this.products.length > 0
      const isEmptyParams = Object.keys(toJson( this.params )).length === 0
      // si parametros de la url no están vacíos y ya hay productos, reseteo
      if( !isEmptyParams && thereAreProducts){                  
        this.setDataQueryParams(null)              
        this.setProducts([])
        this.resetCartCustomerBreakdownOrder()
        this.setRelatedProducts([])   
      }
    },
    async handlerCurrencies(){
      if( this.currencies.length == 0 ){
        const currencies = await this.fetchCurrencies()  
        this.setCurrencies(currencies) //muto state currencies en start           
        const cliente = this.cart.cliente
        const { currency } = cliente
        const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ) )    
        if( !currency ){
          this.setCurrency( divisa )
        } 
      }
    },
    async handlerTokenIsMultiProduct(){
      if(this.user.isMultiProduct){          
          const idCategory = this.queryParams.category
          if(!this.products.length){
            const products = await this.fetchProductsByCategories(idCategory) //obtengo los productos
            this.setProducts(products)    
            if( this.$gtm.enabled() ){
              pushImpressionsToDL()     
            } 
          }
      } else {
        if(!this.products.length){
          const requestsPayload = makePayloadRequestProducts( this.queryParams.items )
          const products = await this.getInitialProducts(requestsPayload)      
          this.setProducts(products)
          if( this.$gtm.enabled() ){
            pushImpressionsToDL()     
          }
        }
      
        if(!this.cart.items.length){                 
          const items = toJson( generateProductsToAddCart( this.queryParams.items, this.products ) )      
          items.forEach( element => { this.addProductInCartInitial(element) })
          showAlertMessage('Aggregate product', 'CheckCircleIcon', `🎉🎉 ${items.length} Products has been added.`, 'success', 5000, 'bottom-right')
        }            
      } 
    }
  },  
}

</script>

<style lang="scss">

@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>



