<template>
    <b-col md="8">       
      <b-card no-body style="max-width: 100%" v-for="product in cart" :key="product.uuid">  
        <b-row no-gutters>                
          <div class="col-xs-6 col-md-3 col-sm-12">
            <b-card-img style="height: 100%" :src="imgUrl + product.coverImage" @error="imgAlternativo"/>
        </div>
        <div class="col-xs-6 col-md-6 col-sm-12" style="border-right: 2px solid #e0e0e0" >
            <b-card-body>
              <div v-if="product.hotelName" class="item-name"><h5>{{ product.hotelName.name || "" }}</h5></div>
              <div><h5>{{ product.name || product.detaildisplay || "" }}</h5></div>
              <span class="item-company">Category: {{ product.categoryName }} </span> <br><br>
              <span for="notes-cliente">{{ charactersLeft(product) }}</span>
              <b-form-textarea id="notes-cliente" v-model="product.clientnote" @input="setNotaProduct(product)">
              </b-form-textarea> 
            <CamposRequeridosMsg :product="product" v-if="!product.requiredFieldsFilled" />

            </b-card-body>
          </div>
          <!-- options to edit -->
          <div class="col-xs-6 col-md-3 col-sm-12 p-2">
            <div class="d-flex justify-content-between mb-1">
              <h4 > ${{ subTotalProductoQty(product) }}</h4>            
              <b-button variant="danger" @click="deleteProductInCart(product)" size="sm"> <feather-icon icon="Trash2Icon"/> <!-- <span>Remove</span> --> </b-button>
            </div>
            <div class="text-center mb-1">
              <span >Qty:</span>
                <b-form-spinbutton
                  v-model="product.qty"
                  size="sm"
                  class="ml-75"
                  min="0"
                  inline
                  @change="changeQty($event, product)"  
                  :readonly="product.categoryName == 'Transfer' || product.categoryName == 'Reservas'"
              />
            </div>
            <div class="text-center">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Date
                </h5>        
                <flat-pickr
                  v-model="product.operationdate"
                  class="form-control"                  
                  :config="product.blackoutDates"
                  @input="setDateOperation(product)"
                />
              </b-form-group>
            </div>         
          </div>
        </b-row>
        
      </b-card>
      <b-alert show variant="danger" v-if="cart.length == 0 " class="mt-2">
        <div class="alert-body text-center"> 😣 <span>There are no products added to the shopping cart</span></div>
      </b-alert> 
    </b-col>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import { currentDate } from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'
import CamposRequeridosMsg from '@/modules/shop/components/orderSummary/CamposRequeridosMsg'

export default {
  mixins: [ market  ],
  data() {
    return { 
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
      imdDefault: require('@/assets/images/default.jpg') ,
      max: 255,
      dateDefault: null,
      fechaActual: currentDate(),
      disabledDates: ["2022-08-10"]
    }
  },
  components: {
    flatPickr,  
    CamposRequeridosMsg  
  },
  computed: {
    ...mapState('shop',['cart']),
    
  },
  methods:{
    ...mapMutations('shop',['removeProductInCart','calculateTotalBreakdown','setNoteItem']),
    subTotalProductoQty(item) {
      return ( Math.round( item.qty * (item.discountApplied > 0 ? item.salepriceWithDiscount : item.saleprice) * 100 ) / 100 ).toFixed(2)                  
    },
    imgAlternativo(event) {
      event.target.src = this.imdDefault
    },
    deleteProductInCart( product ){
      this.removeProductInCart(product.uuid)
    },
    async changeQty(qty, item){      
      if( qty == 0 ){
        this.removeProductInCart(item.uuid)             
      }
      if( qty > 0){
        this.calculateTotalBreakdown()
        await this.qtyHasChanged(item)    // desde el mixim market            
      }                       
    },
    charactersLeft(product){
      return `Write notes: you have ${this.max - product.clientnote.length } characters left.`               
    },
    setNotaProduct(product){
      const { uuid, clientnote } = product
      const payload = { uuid, notaCliente: clientnote, notaInterna: '' }
      this.setNoteItem(payload)
    },
    setDateOperation(product){
      const{ uuid, operationdate } = product
      this.setOperationDateProduct({uuid, date: operationdate })
    }
  }
}

</script>
<style scoped>
.center-qty {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>