<template>
    <b-col md="4">
      <div class="checkout-options" v-if="customerCurrency">        
        <b-card no-body class="border-primary" >
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <h5 class="mb-0">
              Total purchase
            </h5>
            <b-badge variant="success">
              {{customerCurrency.code}}
            </b-badge>
            <small class="text-muted w-100" v-if="breakdown.total > 0">Choose a currency for conversion</small>
          </b-card-header>
          <b-card-body>
            <b-form-select @change="changeCurrency" :value="customerCurrency" v-if="breakdown.total > 0">
              <option v-for="cur in currencies" :key="cur.id" :value="cur"  >{{cur.code}}</option>
            </b-form-select> 
            
            <div class="d-flex justify-content-between mb-2 mt-1">          
              <div>
                <b>Exchange rate: </b><b-badge variant="light-secondary"> {{tipoDeCambio}}</b-badge><br>
                <b>Total currency: </b><b-badge variant="light-secondary"> ${{totalDivisa}} </b-badge>
              </div>
              <div>
                <b>SubTotal:</b><b-badge variant="light-secondary"> ${{subTotal}} </b-badge> <br>
                <!-- <b>Descuento: </b><b-badge variant="light-secondary"> $ 56 </b-badge> <br>                                          -->
                <b>Total:</b> <b-badge variant="light-secondary">${{total}} </b-badge>                  
              </div>
            </div>    
                          
            <b-row>
              <b-col>
                <b-button variant="primary" block :to="{ name: 'payment' }" :disabled="cart.length == 0"> Checkout
                </b-button>
              </b-col>
            </b-row> 
          </b-card-body>
        </b-card>
      </div>
    </b-col>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { toJson } from '@/helpers/helpers'
export default {
  computed: {
    ...mapState('shop',['cart','customerCurrency','breakdown']),
    ...mapState('start',['currencies',]),

    ...mapGetters('shop',['totalDiscountOnAllProducts']),
    tipoDeCambio(){
      const currencieValor = this.customerCurrency ? this.customerCurrency.value : 1 
      return ( (Math.round( (currencieValor) * 100) / 100).toFixed(2) )
    },
    totalDivisa(){
      const currencieValor = this.customerCurrency ? this.customerCurrency.value : 1 
      return ( (Math.round( ( parseFloat(this.breakdown.total) *  parseFloat(currencieValor) ) * 100) / 100).toFixed(2) )
    },
    subTotal(){
      return ((Math.round( (parseFloat(this.totalDiscountOnAllProducts) + parseFloat(this.breakdown.total)) * 100) / 100).toFixed(2) )                
    },
    total(){
      return ( (Math.round( (this.breakdown.total ) * 100) / 100).toFixed(2) )            
    },
  },
  methods:{
    ...mapMutations('shop',['setCurrencyCustomer']),
    changeCurrency(currency){             
      this.setCurrencyCustomer(currency)
    }, 
  },
  watch: {
    breakdown: {
      deep: true,
      handler() {        
        if( this.breakdown.total === 0 ){          
          const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ) )              
          this.setCurrencyCustomer( divisa )          
        }         
      },
    },
  },
}
</script>
