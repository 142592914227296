import { decodeJwt } from "./helpers"

const compareTwoObjects = (one, two) => {
    const isEqual =  JSON.stringify(one) === JSON.stringify(two)
    return isEqual
}
const generateQueryParams = (queryParams) => {    
    let data = null          
      if(queryParams){          
        if(queryParams.payload){
          const { ok, payload } = decodeJwt(queryParams.payload) 
          const hotel = payload.hotel ? parseInt(payload.hotel) : null
          const lang = payload.lang ? payload.lang : null
          const rvhotel = payload.rvhotel ? payload.rvhotel : null
          if(ok){
            data = { token:payload.token, items: payload.items, category: null, hotel, language: generateLang(lang), rvhotel}                             
          }          
        }
        if(queryParams.token){
            const token = queryParams.token
            const hotel = queryParams.hotel ? parseInt(queryParams.hotel) : null
            const lang = queryParams.lang ? queryParams.lang : null 
            const rvhotel = queryParams.rvhotel ? queryParams.rvhotel : null 
            if(queryParams.product){                                                                   
                delete queryParams.token 
                const item = { detail: parseInt(queryParams.detail), product: parseInt(queryParams.product), type: queryParams.type, language: generateLang(lang)}                     
                data = { token, items: [item], category: null, hotel, rvhotel}
            } 
            if(queryParams.category){            
                const category = parseInt(queryParams.category)                                         
                data = { token, items: [], category, hotel, language: generateLang(lang), rvhotel}
            }
            if( queryParams.items && queryParams.items.length > 0 ){                                                           
                data = { token, items: [...queryParams.items], category: null, hotel, language: generateLang(lang), rvhotel}
            }
        } 
        
    }    
    return data
}
const generateLang = (lang) => {
    if( lang ){
        const idLangs = [{ idioma: 'es', id: 1 }, { idioma: 'en', id: 2}, { idioma: 'br', id: 3} ]//id langs
        const langValid = ['es', 'en', 'br'].includes(lang) ? lang : 'es' // si lang viene, ver si es válido y usarlo, sino poner default lang como 'es'
        const language = idLangs.find( lang => lang.idioma === langValid ) // busco el id que voy a enviar
        return language.id       
      } else {
        return 1      
    }
}
export {
    generateQueryParams,
    generateLang,
    compareTwoObjects
}